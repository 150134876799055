import { Box, Stack, Typography } from '@mui/material';
import { privacyPolicy } from '#/components/pages/TCPP/mockTerms';
import useLocales from '#/hooks/useLocales';

const PrivacyPolicy = () => {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 10,
        px: {
          xs: 2,
          md: 15,
        },
        gap: 8,
      }}
    >
      <Typography variant="h1" textAlign="center">
        {String(translate('privacyPolicy.title'))}
      </Typography>
      <Typography variant="body1">
        {String(translate('global.lastUpdated'))}{' '}
        {new Date().toLocaleDateString()}
      </Typography>
      {privacyPolicy.map((pp, index) => {
        const section = pp?.value;
        return (
          <Stack key={index} spacing={2}>
            <Typography variant="h5">
              {String(translate(`privacyPolicy.${section}.title`))}
            </Typography>

            {pp.content &&
              pp.content.map((content, index) => (
                <Typography key={index} variant="body1">
                  {String(
                    translate(`privacyPolicy.${section}.content.${content}`, {
                      returnObjects: true,
                    })
                  )}
                </Typography>
              ))}
          </Stack>
        );
      })}
    </Box>
  );
};

export default PrivacyPolicy;
