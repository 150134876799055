import Page from '#/components/shared/ui/Page';
import PrivacyPolicyContent from '#/components/pages/TCPP/PrivacyPolicy';

const PrivacyPolicy = () => (
  <Page title="Privacy Policy">
    <PrivacyPolicyContent />
  </Page>
);

export default PrivacyPolicy;
